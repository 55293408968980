<template functional>
  <div class="">
    <p class="back__link">
      <router-link :to="{name: 'BrandStoreTop', params: {code: props.brandCode}}" v-scroll-to="{el: '#brandNav' ,container: '.container',duration: 300,easing: 'linear',offset: -46,force: true,x: false,y: true}">
        <span class="back__arrow"><font-awesome-icon icon="chevron-right" /></span>
        <span class="name">都道府県から選ぶ</span>
      </router-link>
    </p>
    <h1 class="list_title">{{props.listTitle}}</h1>
    <ul class="pref__list--inner static">
      <li v-for="city in props.lists" :key="city.city_name_ja">
        <router-link :to="props.linkpath + city.city_code + '/'"  v-scroll-to="{el: '#brandNav' ,container: '.container',duration: 300,easing: 'linear',offset: -46,force: true,x: false,y: true}">
          <span class="name">{{city.city_name_ja}}</span>
          <span class="num">{{city.count}}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['brandCode', 'listTitle', 'lists', 'linkpath']
}
</script>
